import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/Picture1.png";
import Tilt from "react-parallax-tilt";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import { FaSnapchat, FaSnapchatGhost } from 'react-icons/fa';

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
              I fell in love with Data, a love that I now channel into my role as a <b className="purple"> Data Analyst </b>at <b className="purple"> TVA, </b> where I apply my expertise to make meaningful contributions.
              <br />
              <br />I know Technical tools and languages like
              <i>
                <b className="purple"> Python, Java, C, HTML/CSS, Power BI, Power Automate, Service Now, MySQL</b>
              </i>
              <br />
              <br />
              My field of Interest's are in &nbsp;
              <i>
                <b className="purple">Product Management, Data Analytics, Data Science, </b> and
                also in areas related to{" "}
                <b className="purple">
                Web Technologies, Machine Learning,</b> and <b className="purple">Artificial Intelligence (AI).
                </b>
              </i>
              <br />
              <br />
              In my part time, I also apply my passion for jewelry business by <b className="purple">Designing Jewelry, Customizing Chains</b> and showcasing them on my 
              <i>
                <b className="purple">
                  {" "}
                 Instagram business page
                </b>
              </i>
              &nbsp; @ 
              <i>
              <b className="purple"> 
    <a href="https://www.instagram.com/alex_the_jeweler_901/" target="_blank" rel="noopener noreferrer">
      alex_the_jeweler_901
    </a>
  </b>
              </i>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/anasmeer786"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/anas-zaheer/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/anas_zaheer1"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
              <li className="social-icons">
              <a
                href="https://t.snapchat.com/IvUCGGCz"
                target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
              >
                <FaSnapchat />
              </a>
            </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
