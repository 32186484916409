// CertificateItem.js
import React from "react";
import Card from "react-bootstrap/Card";

function CertificateItem(props) {
  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Link href={props.pdfLink} target="_blank">View Certificate</Card.Link>
      </Card.Body>
    </Card>
  );
}

export default CertificateItem;