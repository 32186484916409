import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiMicrosoftexcel,
  SiMysql,
  SiPowerbi,
  SiPowerautomate,
  SiXcode,
  SiMicrosoftpowerpoint,
  SiFigma,
  SiPython,
  SiHtml5,
  SiCss3,
  SiJavascript,
  SiR,
  SiRubyonrails,
  SiPowershell,
  SiAndroidstudio,
  SiDjango,
  SiFlutter,
  SiLatex,
  SiMicrosoftword,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiHtml5 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiCss3 />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiJavascript />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiR />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRubyonrails />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPowershell />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiAndroidstudio />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDjango />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFlutter />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLatex />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftexcel/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPowerbi/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPowerautomate/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiXcode/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFigma/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftpowerpoint/>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMicrosoftword />
      </Col>

    </Row>
  );
}

export default Toolstack;
