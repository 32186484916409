import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import CertificateItem from "./CertificateItem";
import certificate1 from "../../Assets/Certificates/thumbnail.jpg";
import certificate1Pdf from "../../Assets/Certificates/thumbnail.jpg";
import certificate2 from "../../Assets/Certificates/Dean2023Spring.jpg";
import certificate2pdf from "../../Assets/Certificates/Dean2023Spring.jpg";
import certificate3 from "../../Assets/Certificates/fall2020.jpg";
import certificate3pdf from "../../Assets/Certificates/Fall2020.pdf";
import certificate4 from "../../Assets/Certificates/fall2022.jpg";
import certificate4pdf from "../../Assets/Certificates/fall2022.jpg";
import certificate5 from "../../Assets/Certificates/spring2021.jpg";
import certificate5pdf from "../../Assets/Certificates/Spring2021.pdf";
import certificate6 from "../../Assets/Certificates/spring2022.jpg";
import certificate6pdf from "../../Assets/Certificates/Spring2022.pdf";
import certificate7 from "../../Assets/Certificates/newmemphis.jpg";
import certificate7pdf from "../../Assets/Certificates/newmemphis.jpg";
import certificate19 from "../../Assets/Certificates/greenbelt.jpg";
import certificate19pdf from "../../Assets/Certificates/Green_Belt.pdf";
import certificate20 from "../../Assets/Certificates/blackbelt.jpg";
import certificate20pdf from "../../Assets/Certificates/Black_Belt.pdf";
import certificate8 from "../../Assets/Certificates/ML.jpg";
import certificate8pdf from "../../Assets/Certificates/CertificateOfCompletion_Artificial Intelligence Foundations Machine Learning (1).pdf";
import certificate9 from "../../Assets/Certificates/PowerBI.jpg";
import certificate9pdf from "../../Assets/Certificates/CertificateOfCompletion_Power BI.pdf";
import certificate10 from "../../Assets/Certificates/productmana.jpg";
import certificate10pdf from "../../Assets/Certificates/certificateOfPM.pdf";
import certificate11 from "../../Assets/Certificates/AgileFound.jpg";
import certificate11pdf from "../../Assets/Certificates/CertificateOfCompletion_Agile Foundations.pdf";
import certificate12 from "../../Assets/Certificates/AWS.jpg";
import certificate12pdf from "../../Assets/Certificates/CertificateOfCompletion_AWS Certified Cloud Practitioner CLFC01 Cert Prep 1 Cloud Concepts (1).pdf";
import certificate13 from "../../Assets/Certificates/DevOps.jpg";
import certificate13pdf from "../../Assets/Certificates/CertificateOfCompletion_DevOps Foundations DevSecOps.pdf";
import certificate14 from "../../Assets/Certificates/ITIL.jpg";
import certificate14pdf from "../../Assets/Certificates/CertificateOfCompletion_Intro to Service Management with ITIL 4 (1).pdf";
import certificate15 from "../../Assets/Certificates/Buzediq.jpg";
import certificate15pdf from "../../Assets/Certificates/CertificateOfCompletion_Business Etiquette Phone Email and Text (1).pdf";
import certificate16 from "../../Assets/Certificates/effecmemb.jpg";
import certificate16pdf from "../../Assets/Certificates/CertificateOfCompletion_Being an Effective Team Member 2020.pdf";
import certificate17 from "../../Assets/Certificates/zerotrust.jpg";
import certificate17pdf from "../../Assets/Certificates/CertificateOfCompletion_Understanding Zero Trust.pdf";
import certificate18 from "../../Assets/Certificates/microsoft.jpg";
import certificate18pdf from "../../Assets/Certificates/CertificateOfCompletion_Optimizing Your Work with Microsoft 365.pdf";

function Certificates() {
  return (
    <Container fluid className="project-section">
      <Container>
        <h1 className="project-heading">
          Anas's <strong className="purple">Certificates </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate2}
              title="Dean List Spring 2023"
              pdfLink={certificate2pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate6}
              title="Dean List Spring 2022"
              pdfLink={certificate6pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate4}
              title="Dean List Fall 2022"
              pdfLink={certificate4pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate5}
              title="Dean List Spring 2021"
              pdfLink={certificate5pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate1}
              title="Dean List Fall 2021"
              pdfLink={certificate1Pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate3}
              title="Dean List Fall 2020"
              pdfLink={certificate3pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate7}
              title="New Memphis Accelerate Graduate"
              pdfLink={certificate7pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate19}
              title="Lean Six Sigma Green Belt"
              pdfLink={certificate19pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate20}
              title="Lean Six Sigma Black Belt"
              pdfLink={certificate20pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate10}
              title="Product Management Fundamentals"
              pdfLink={certificate10pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate8}
              title="Machine Learning LinkedInLearning"
              pdfLink={certificate8pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate9}
              title="Power BI LinkedInLearning"
              pdfLink={certificate9pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate11}
              title="Agile Foundations LinkedInLearning"
              pdfLink={certificate11pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate12}
              title="AWS certified Cloud PractitionerLinkedInLearning"
              pdfLink={certificate12pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate13}
              title="DevSecOps LinkedInLearning"
              pdfLink={certificate13pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate14}
              title="Service Management with ITIL LinkedInLearning"
              pdfLink={certificate14pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate15}
              title="Business Etiquette LinkedInLearning"
              pdfLink={certificate15pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate16}
              title="Being an Effective Team Member LinkedInLearning"
              pdfLink={certificate16pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate17}
              title="Understanding Zero Trust LinkedInLearning"
              pdfLink={certificate17pdf}
            />
          </Col>
          <Col md={4} className="project-card">
            <CertificateItem
              imgPath={certificate18}
              title="Optimizing Work with Microsoft
              365 LinkedInLearning"
              pdfLink={certificate18pdf}
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Certificates;