import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I was born and raised in a small village of <span className="purple">Pakistan </span>
            and moved to the US in <span className="purple"> 2018.</span>
            <br /> Recently, in Fall 2023, I graduated from University of Memphis with a double Bachelor's
            in <span className="purple">Computer Science Honors</span> and <span className="purple">Mathematics Honors, </span> earning the distinction of <span className="purple">Summa Cum Laude Honor.</span>
            <br /> Alongside my college journey, I also gained valuable Business experience in the <span className="purple">jewelry industry, </span>accumulating <span className="purple">5 years of expertise, </span>and I am still connected with it. 
            <br />
            After Completing my degrees, I started working as an <span className="purple">Analyst </span>at <span className="purple">TVA (Tennessee Valley Authority)</span>.
            <br />
            <br />
            Apart from that, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Cricket
            </li>
            <li className="about-activity">
              <ImPointRight /> Trading stocks
            </li>
            <li className="about-activity">
              <ImPointRight /> Exploring new Business Ideas
            </li>
            <li className="about-activity">
              <ImPointRight /> Spending time with Family
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Push your limits everyday to be better than others!"{" "}
          </p>
          <footer className="blockquote-footer">Anas</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
