import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import leaf from "../../Assets/Projects/clientserver.png";
import emotion from "../../Assets/Projects/houseprediction.png";
import editor from "../../Assets/Projects/Parser.png";
import chatify from "../../Assets/Projects/bmi.png";
import suicide from "../../Assets/Projects/sentiment.png";
import bitsOfCode from "../../Assets/Projects/Jewelry.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Chat Server"
              description="A chat application which is done using sockets and threading in Python. It consists of both client and server scripts. How to Run: Execute the server script (server.py) on your machine. Run the client script (client.py) on multiple machines or in separate terminal windows. Input your name when prompted by the client script. Start engaging in conversations with other connected clients."
              ghLink="https://github.com/anasmeer786/ChatServer"
                          
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Sentiment Analysis"
              description="Project done on the Machine Learning concept where conducted sentiment analysis (a subset of natural language processing, plays a pivotal role in understanding the opinions and emotions expressed in textual data) on a dataset of 50,000 IMDb movie reviews. The dataset was explored, preprocessed, and utilized to train various machine learning models, including Logistic Regression, Support Vector Machines (SVM), Naive Bayes, K-Nearest Neighbors (KNN), and Random Forest."
              ghLink="https://github.com/anasmeer786/Sentiment-Analysis"
                          
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Houses Prediction Model"
              description="This project focuses on predicting house prices using advanced regression techniques. The language used for this project is R. The primary goal is to analyze the relationship between various features and the sale price of houses. Data Analysis: Identified key predictors such as 'GrLivArea,' 'YearBuilt,' and 'OverallQual' in relation to house prices. Checked for collinearity among predictors to ensure model stability. Participated in the 'House Prices: Advanced Regression Techniques' competition on Kaggle."
              ghLink="https://github.com/anasmeer786/HousePricesPredictionusingKNN-in-R"
                          
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="BMI Calculator"
              description="This a BMI calculator app, which asks users to put their weight, height, and age. Then, it calculates the BMI for the users and gives the feedback on if they have low, high or average BMI and what should they do to control it."
              ghLink="https://github.com/anasmeer786/BMI_Calculator-using-Flutter"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Jewelry e-comerace Website"
              description="This is an e-comerace website built using ruby on rails and HTML. This is a marketplace for buying and selling jewelry. Some of the core features are: Search Bar and Filters, Separate log ins for users and sellers, Checkout Function, Add to cart, History, details of item, Adding new item as seller, Item Sold history for seller, item purchase history and Items ownership."
              ghLink="https://github.com/anasmeer786/team1-s23"
              
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="Costumer Parser"
              description="Task of this project is to write a parser for a customer form using Java and paraboiled libraries. The program include a grammar for the parser and display the parse tree. The customer form include the following structure: First name, middle name (optional), last name Street address, city, state (or province), country Phone number."
              ghLink="https://github.com/anasmeer786/CostumerParser"
                          
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
