import React from "react";
import { IoIosChatbubbles } from "react-icons/io";
import { Container, Row, Col } from "react-bootstrap";

function Languages() {
  const languages = ["English", "Urdu", "Pashto", "Hindko", "Arabic", "Punjabi"];

  return (
    <Container style={{ display: 'flex', justifyContent: 'center' }}>
      <Row>
        {languages.map((language, index) => (
          <Col xs={12} md={4} key={index} className="about-activity" style={{ fontSize: '30px' }}>
            <IoIosChatbubbles /> {language}
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default Languages;

// import React from "react";
// import { Col, Row } from "react-bootstrap";
// import { CgCPlusPlus } from "react-icons/cg";
// import {
//   DiJavascript1,
//   DiReact,
//   DiNodejs,
//   DiMongodb,
//   DiPython,
//   DiGit,
//   DiJava,
// } from "react-icons/di";
// import {
//   SiRedis,
//   SiFirebase,
//   SiNextdotjs,
//   SiSolidity,
//   SiPostgresql,
// } from "react-icons/si";
// import { TbBrandGolang } from "react-icons/tb";

// function Techstack() {
//   return (
//     <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
//       <Col xs={4} md={2} className="tech-icons">
//         <CgCPlusPlus />
//         </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <DiPython />
//       </Col>
//       <Col xs={4} md={2} className="tech-icons">
//         <DiJava />
//       </Col>
//     </Row>
//   );
// }

// export default Techstack;
